import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { Flipper, Flipped } from 'react-flip-toolkit'

import AnimatedFlcIcon from '../atoms/AnimatedFlcIcon'
import SvgFlcIconOutline from '../atoms/SvgFlcIconOutline'

import {
    OVERLAY_OPACITY,
    TEXT_SHADOW,
    MAX_CONTENT_WIDTH_SML,
} from '../../constants/global.js'

const LOGO_TITLE_FONT_SML = 48
const LOGO_SUBTITLE_FONT_SML = 16
const LOGO_SCALE_UP = 1.8

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        padding: `${theme.spacing(15)}px ${theme.spacing(3)}px`,
        textAlign: 'center',
        textShadow: TEXT_SHADOW,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflow: 'hidden',
        '&:before': {
            content: "''",
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.palette.primary.main,
            opacity: OVERLAY_OPACITY,
            zIndex: 1,
        },
    },
    bannerImage: {
        position: 'absolute!important',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 0,
    },
    iconLarge: {
        display: 'inline-block',
        position: 'absolute',
        width: '70vw',
        maxWidth: '350px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
    },
    iconSmall: {
        display: 'inline-block',
        position: 'relative',
        width: '72px',
        transform: 'none',
        zIndex: 2,
        [theme.breakpoints.up('sm')]: {
            width: '108px',
            zIndex: 2,
        },
    },
    bannerCopyHidden: {
        display: 'block',
        maxWidth: MAX_CONTENT_WIDTH_SML,
        position: 'relative',
        margin: `${theme.spacing(2)}px auto 0 auto`,
        transform: 'translateY(50vh)',
        opacity: '0',
        zIndex: 2,
    },
    bannerCopyVisible: {
        display: 'block',
        maxWidth: MAX_CONTENT_WIDTH_SML,
        position: 'relative',
        margin: `${theme.spacing(2)}px auto 0 auto`,
        transform: 'none',
        opacity: '1',
        transition: 'opacity 1.3s ease-out',
        zIndex: 2,
    },
    bannerTitle: {
        textTransform: 'uppercase',
        fontSize: `${LOGO_TITLE_FONT_SML}px`,
        lineHeight: 1,
        fontWeight: theme.typography.fontWeightLight,
        letterSpacing: '3px',
        marginTop: 0,
        marginBottom: '32px',
        [theme.breakpoints.up('sm')]: {
            fontSize: `${LOGO_TITLE_FONT_SML * LOGO_SCALE_UP}px`,
        },
    },
    bannerSubtitle: {
        display: 'block',
        fontSize: `${LOGO_SUBTITLE_FONT_SML}px`,
        lineHeight: 1,
        letterSpacing: '3px',
        marginTop: `${theme.spacing(2)}px`,
        [theme.breakpoints.up('sm')]: {
            fontSize: `${LOGO_SUBTITLE_FONT_SML * LOGO_SCALE_UP}px`,
        },
    },
}))

function PageHeaderHome({ blurb, image }) {
    const classes = useStyles()
    const [bannerImageLoaded, setBannerImageLoaded] = useState(false)
    const [iconAnimationComplete, setIconAnimationComplete] = useState(false)
    const [homeAnimationSeen, setHomeAnimationSeen] = useState(false)

    useEffect(() => {
        if (sessionStorage.getItem('homeAnimationSeen') && !homeAnimationSeen) {
            setHomeAnimationSeen(true)
            setIconAnimationComplete(true)
        }
    }, [homeAnimationSeen])

    function handleIconAnimationComplete() {
        sessionStorage.setItem('homeAnimationSeen', true)

        setTimeout(() => {
            setIconAnimationComplete(true)
        }, 500)
    }

    return (
        <header className={classes.root}>
            <Img
                fluid={image.fluid}
                className={classes.bannerImage}
                imgStyle={{
                    margin: 0,
                }}
                onLoad={() => setBannerImageLoaded(true)}
            />
            {bannerImageLoaded && (
                <Flipper
                    flipKey={iconAnimationComplete}
                    spring={{
                        stiffness: 110,
                        damping: 18,
                    }}
                >
                    <Flipped flipId="icon">
                        <div
                            className={
                                iconAnimationComplete
                                    ? classes.iconSmall
                                    : classes.iconLarge
                            }
                        >
                            {homeAnimationSeen ? (
                                <SvgFlcIconOutline />
                            ) : (
                                <AnimatedFlcIcon
                                    color={'#ffffff'}
                                    duration={2500}
                                    delay={500}
                                    onComplete={handleIconAnimationComplete}
                                />
                            )}
                        </div>
                    </Flipped>
                    <Flipped flipId="copy" translate opacity>
                        <div
                            className={
                                iconAnimationComplete
                                    ? classes.bannerCopyVisible
                                    : classes.bannerCopyHidden
                            }
                        >
                            <Typography
                                className={classes.bannerTitle}
                                component="h1"
                                variant="h1"
                            >
                                Fine Lines
                                <span className={classes.bannerSubtitle}>
                                    Construction
                                </span>
                            </Typography>
                            <Typography
                                variant="body1"
                                component={'div'}
                                dangerouslySetInnerHTML={{ __html: blurb }}
                            />
                        </div>
                    </Flipped>
                </Flipper>
            )}
        </header>
    )
}

PageHeaderHome.propTypes = {
    blurb: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
}

export default PageHeaderHome
