import React from 'react'
import Img from 'gatsby-image'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import {
    SPLASHNAV_OVERLAY_OPACITY,
    SPLASHNAV_OVERLAY_OPACITY_ACTIVE,
    TRANSITION_ALL,
    TEXT_SHADOW,
} from '../../constants/global.js'

const styles = (theme) => ({
    root: {
        height: '100vw',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        cursor: 'pointer',
        '&:hover': {
            '&:after': {
                backgroundColor: `rgba(${theme.palette.primary.rgb}, ${SPLASHNAV_OVERLAY_OPACITY_ACTIVE})`,
            },
        },
        '&:after': {
            display: 'block',
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: `rgba(${theme.palette.primary.rgb}, ${SPLASHNAV_OVERLAY_OPACITY_ACTIVE})`,
            transition: TRANSITION_ALL,
            zIndex: 0,
        },
        [theme.breakpoints.up('sm')]: {
            height: '70vw',
        },
        [theme.breakpoints.up('md')]: {
            height: 'inherit',
            '&:after': {
                backgroundColor: `rgba(${theme.palette.primary.rgb}, ${SPLASHNAV_OVERLAY_OPACITY})`,
            },
        },
    },
    title: {
        position: 'relative',
        textTransform: 'uppercase',
        textShadow: TEXT_SHADOW,
        userSelect: 'none',
        margin: 0,
        fontSize: '60px',
        fontWeight: theme.typography.fontWeightLight,
        lineHeight: 1,
        textAlign: 'center',
        zIndex: 1,
        [theme.breakpoints.up('sm')]: {
            fontSize: '72px',
            // eslint-disable-next-line
            fontSize: '10vw',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '84px',
            // eslint-disable-next-line
            fontSize: '8vw',
            '.rotate-text & ': {
                transform: 'rotate(-90deg)',
            },
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '100px',
            // eslint-disable-next-line
            fontSize: '8vw',
        },
    },
    image: {
        position: 'absolute!important',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
})

function splashNavItem({ classes, className, title, image, link, rotateText }) {
    return (
        <article
            onClick={() => navigate(`${link}/`)}
            className={classnames(
                classes.root,
                className ? className : null,
                rotateText ? 'rotate-text' : null
            )}
        >
            <Img fluid={image} className={classes.image} />
            <Typography className={classes.title} component="h2" variant="h2">
                {title}
            </Typography>
        </article>
    )
}

splashNavItem.propTypes = {
    link: PropTypes.string,
    image: PropTypes.object.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    className: PropTypes.string,
    rotateText: PropTypes.bool,
}

export default withStyles(styles)(splashNavItem)
