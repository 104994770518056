import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import anime from 'animejs'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    svg: {
        fill: 'transparent',
    },
})

const AnimatedFlcIcon = ({ color, duration, delay, onComplete, className }) => {
    const classes = useStyles()

    useEffect(() => {
        const animSvg = anime
            .timeline({
                targets: '.animated-flc-icon__svg',
                easing: 'easeInOutSine',
                duration: duration,
                delay: duration / 2,
                fill: color,
            })
            .add({
                strokeWidth: 0,
            })

        const animPaths = anime({
            targets: '.animated-flc-icon__path',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: duration,
            delay: function (el, i) {
                return i * delay
            },
            complete: onComplete,
        })

        animSvg.play()
        animPaths.play()

        return () => {
            animSvg.pause()
            animPaths.pause()
        }
    })

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130 190"
            stroke={color}
            strokeWidth="1"
            className={classnames(
                'animated-flc-icon__svg',
                classes.svg,
                className && className
            )}
        >
            <g>
                <path
                    className="animated-flc-icon__path"
                    d="M0 9.812v120.142C0 163.118 29.082 190 65 190c35.919 0 65-26.882 65-60.047V9.812H0zm124.873 118.566c0 31.271-26.639 56.626-59.976 56.626-33.335 0-59.975-25.354-59.975-56.626V15.067h119.95v113.311zM0 0h130v5.012H0z"
                />
                <path
                    className="animated-flc-icon__path"
                    d="M87.269 125.042a7.491 7.491 0 00-7.497 7.496 7.497 7.497 0 0014.994 0 7.493 7.493 0 00-7.497-7.496zm0 9.987a2.492 2.492 0 01-2.498-2.491 2.498 2.498 0 112.498 2.491zM85.021 89.957v9.875h-5.485v-4.937H40.043v40.036h4.936V99.832h5.485v40.035H35.105v-49.91zM79.536 60.343v-4.938H50.464v24.132h29.072V74.6h5.485v10.42H44.979V50.468h40.042v9.875z"
                />
                <path
                    className="animated-flc-icon__path"
                    d="M94.895 40.047v20.296h-4.937V44.984H40.043V85.02h-4.938V40.047z"
                />
            </g>
        </svg>
    )
}

AnimatedFlcIcon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    delay: PropTypes.number.isRequired,
    onComplete: PropTypes.func,
}

export default AnimatedFlcIcon
