import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import PhoneIcon from '@material-ui/icons/PhoneIphone'

import ExternalLink from '../atoms/ExternalLink'
import InternalLinkButton from '../atoms/InternalLinkButton'
import SplashNavItem from '../molecules/SplashNavItem'
import SvgFlcIconOutline from '../atoms/SvgFlcIconOutline'

import { TRANSITION_ALL, HOVER_OPACITY } from '../../constants/global'

const useStyles = makeStyles((theme) => ({
    splashNavRow: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    splashNavRow1: {
        [theme.breakpoints.up('md')]: {
            height: '35vw',
        },
    },
    splashNavRow2: {
        [theme.breakpoints.up('md')]: {
            height: '44vw',
        },
    },
    splashNavItemBuild: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '45%',
        },
    },
    splashNavItemExtend: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '55%',
        },
    },
    splashNavItemReno: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '60%',
        },
    },
    splashNavItemFlc: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${theme.spacing(8)}px 0`,
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            width: '40%',
        },
    },
    splashNavItemFlcIcon: {
        width: '40%',
        maxWidth: '160px',
        [theme.breakpoints.up('md')]: {
            maxWidth: '180px',
        },
    },
    phoneLink: {
        marginTop: `${theme.spacing(3)}px`,
        fontSize: theme.typography.pxToRem(20),

        '& svg': {
            position: 'relative',
            top: '2px',
            marginRight: `${theme.spacing(1)}px`,
        },
    },
    contactUsLink: {
        marginTop: `${theme.spacing(3)}px`,
    },
    socialLinks: {
        textAlign: 'center',
        marginTop: `${theme.spacing(3)}px`,
    },
    socialLink: {
        display: 'inline-block',
        margin: `0 ${theme.spacing(1)}px`,
        transition: TRANSITION_ALL,
        '&:hover': {
            opacity: HOVER_OPACITY,
        },
        '& svg': {
            height: '35px',
            [theme.breakpoints.up('sm')]: {
                height: '45px',
            },
        },
    },
}))

function SplashNav({ splashData }) {
    const classes = useStyles()
    const {
        buildImage,
        buildPage,
        extendImage,
        extendPage,
        renovateImage,
        renovatePage,
    } = splashData

    return (
        <>
            <section
                className={classnames(
                    classes.splashNavRow,
                    classes.splashNavRow1
                )}
            >
                <SplashNavItem
                    rotateText={true}
                    className={classes.splashNavItemBuild}
                    title={'Build'}
                    image={buildImage.fluid}
                    link={buildPage.slug}
                />
                <SplashNavItem
                    className={classes.splashNavItemExtend}
                    title={'Extend'}
                    image={extendImage.fluid}
                    link={extendPage.slug}
                />
            </section>
            <section
                className={classnames(
                    classes.splashNavRow,
                    classes.splashNavRow2
                )}
            >
                <SplashNavItem
                    className={classes.splashNavItemReno}
                    title={['Reno', <br key={'break'} />, 'vate']}
                    image={renovateImage.fluid}
                    link={renovatePage.slug}
                />
                <article className={classes.splashNavItemFlc}>
                    <SvgFlcIconOutline
                        color={'#ffffff'}
                        className={classes.splashNavItemFlcIcon}
                    />
                    <ExternalLink
                        className={classes.phoneLink}
                        link={'tel:021 883 985'}
                    >
                        <PhoneIcon />
                        021 883 985
                    </ExternalLink>
                    <InternalLinkButton
                        link={'/contact/'}
                        text={'Contact Us'}
                        variant={'secondary'}
                        className={classes.contactUsLink}
                    />
                    <div className={classes.socialLinks}>
                        <ExternalLink
                            className={classes.socialLink}
                            link="https://www.facebook.com/finelinesconstructionnz/"
                            gaEventLabel={
                                'SplashNav: https://www.facebook.com/finelinesconstructionnz/'
                            }
                            title="Fine Lines Construction Facebook"
                        >
                            <svg
                                viewBox="0 0 50 50"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="#FFFFFF"
                                    fillRule="evenodd"
                                    d="M25 50c13.807 0 25-11.193 25-25S38.807 0 25 0 0 11.193 0 25s11.193 25 25 25zm0-3c12.15 0 22-9.85 22-22S37.15 3 25 3 3 12.85 3 25s9.85 22 22 22zm1.815-11V25h3.254l.43-3.792h-3.685l.005-1.898c0-.99.1-1.518 1.623-1.518h2.034V14h-3.255c-3.91 0-5.285 1.84-5.285 4.932v2.276H19.5v3.79h2.437V36h4.878zm0 0"
                                />
                            </svg>
                        </ExternalLink>
                        <ExternalLink
                            className={classes.socialLink}
                            link="https://www.instagram.com/finelinesconstructionnz/"
                            gaEventLabel={
                                'SplashNav: https://www.instagram.com/finelinesconstructionnz/'
                            }
                            title="Fine Lines Construction Instagram"
                        >
                            <svg
                                viewBox="0 0 50 50"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="#FFFFFF"
                                    fillRule="evenodd"
                                    d="M25 0C11.193 0 0 11.193 0 25s11.193 25 25 25 25-11.193 25-25S38.807 0 25 0zm0 3C12.85 3 3 12.85 3 25s9.85 22 22 22 22-9.85 22-22S37.15 3 25 3zm10.95 31.51c.02-.102.034-.206.05-.31V15.8c-.015-.102-.03-.203-.047-.304-.193-1.03-1.006-1.83-2.005-1.973-.04-.006-.082-.015-.123-.023h-17.65c-.107.02-.214.035-.32.06-.974.232-1.695 1.053-1.834 2.085-.004.043-.013.086-.02.13v18.452c.02.117.036.235.06.35.217.997 1.03 1.77 1.998 1.9.047.006.094.015.14.023h17.603c.116-.02.23-.037.343-.063.9-.2 1.633-.983 1.808-1.927zM16.38 33.1c0 .49.406.91.874.91h15.488c.473 0 .876-.42.876-.914v-10.27h-2.1c.296.99.388 2 .27 3.026-.116 1.028-.432 1.984-.948 2.867s-1.185 1.616-2.004 2.198c-2.124 1.512-4.92 1.645-7.172.323-1.14-.666-2.034-1.597-2.657-2.797-.93-1.79-1.08-3.67-.533-5.618H16.38V33.1zm8.518-3.442c2.49.056 4.505-2.025 4.558-4.555.054-2.61-1.96-4.765-4.456-4.764-2.44-.002-4.423 2.05-4.458 4.59-.035 2.607 1.962 4.674 4.356 4.728zm8.72-11.176v-2.18c0-.52-.394-.934-.892-.934h-2.078c-.496 0-.892.416-.892.936 0 .72-.002 1.44.002 2.16 0 .108.02.22.053.32.125.384.46.62.872.62.335.002.67 0 1.003 0 .362-.003.725.004 1.088-.005.465-.013.845-.43.845-.918zm0 0"
                                />
                            </svg>
                        </ExternalLink>
                    </div>
                </article>
            </section>
        </>
    )
}

SplashNav.propTypes = {
    splashData: PropTypes.object.isRequired,
}

export default SplashNav
