import React from 'react'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import SEO from '../components/seo'
import Layout from '../components/layout'

import ContentArticle from '../components/atoms/ContentArticle'
import InternalLinkButton from '../components/atoms/InternalLinkButton'
import ContentSection, {
    contentSectionWidths,
} from '../components/atoms/ContentSection'
import CtaBanner from '../components/molecules/CtaBanner'
import SplashNav from '../components/organisms/SplashNav'
import PageHeaderHome from '../components/organisms/PageHeaderHome'

const useStyles = makeStyles((theme) => ({
    aboutUs: {
        marginTop: theme.spacing(20),
    },
}))

const IndexPage = (data) => {
    const classes = useStyles()
    const {
        bannerImage,
        bannerText,
        aboutTitle,
        aboutText,
        servicesTitle,
        servicesText,
        ctaBanner,
        seoTitle,
        seoDescription,
    } = data.data.contentfulHomePage

    return (
        <Layout disableOverlayLogoLink>
            <SEO title={seoTitle} description={seoDescription} />
            <PageHeaderHome
                image={bannerImage}
                blurb={bannerText.childMarkdownRemark.html}
            />
            <SplashNav splashData={data.data.contentfulHomePage} />
            <ContentSection width={contentSectionWidths.small}>
                <ContentArticle className={classes.aboutUs}>
                    <Typography variant="h1">{aboutTitle}</Typography>
                    <Typography
                        component={'div'}
                        dangerouslySetInnerHTML={{
                            __html: aboutText.childMarkdownRemark.html,
                        }}
                    />
                </ContentArticle>
                <ContentArticle>
                    <Typography variant="h1">{servicesTitle}</Typography>
                    <Typography
                        component={'div'}
                        dangerouslySetInnerHTML={{
                            __html: servicesText.childMarkdownRemark.html,
                        }}
                        className={classes.servicesList}
                    />
                </ContentArticle>
            </ContentSection>
            {ctaBanner && (
                <CtaBanner
                    title={ctaBanner.bannerTitle}
                    description={ctaBanner.bannerText}
                >
                    {ctaBanner.bannerLinks &&
                        ctaBanner.bannerLinks.map((link) => {
                            return (
                                <InternalLinkButton
                                    key={link.link}
                                    link={link.link}
                                    text={link.label}
                                    variant={link.variant}
                                />
                            )
                        })}
                </CtaBanner>
            )}
        </Layout>
    )
}

export default IndexPage

export const indexPageQuery = graphql`
    query indexPageQuery {
        contentfulHomePage(slug: { eq: "home" }) {
            id
            title
            bannerImage {
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
            bannerText {
                childMarkdownRemark {
                    html
                }
            }
            buildImage {
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
            extendImage {
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
            renovateImage {
                fluid {
                    ...GatsbyContentfulFluid
                }
            }
            buildPage {
                slug
            }
            extendPage {
                slug
            }
            renovatePage {
                slug
            }
            aboutTitle
            aboutText {
                childMarkdownRemark {
                    html
                }
            }
            servicesTitle
            servicesText {
                childMarkdownRemark {
                    html
                }
            }
            ctaBanner {
                bannerText
                bannerTitle
                bannerLinks {
                    label
                    link
                    variant
                }
            }
            seoTitle
            seoDescription
        }
    }
`
